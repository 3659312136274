import React from 'react';
import DesktopNavbar from './DesktopNavbar';

const PAGES = [
  {
    title: 'Soluciones',
    url: 'home-solutions',
    id: 'home-solutions',
  },
  {
    title: 'Features',
    url: 'home-features',
    id: 'home-features',
  },
  {
    title: 'Tarifas',
    url: 'home-pricing',
    id: 'home-pricing',
  },
  {
    title: 'FAQ',
    url: 'home-faq',
    id: 'home-faq',
  },
];

function KobrosNavbar() {
  return (
    <DesktopNavbar pages={PAGES} />
  );
}

export default KobrosNavbar;
