import React, { useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIsFirstTimeVisible, useIsMobile } from '../../hooks';
import KobrosPlatformImage from '../../assets/home/images/kobros_platform.svg';
import { KobrosLogo } from '../Utils/logo';
import { KobrosDescription } from '../Utils/text';

function KobrosSection() {
  const isMobile = useIsMobile();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });
  const navigate = useNavigate();

  const kobrosSectionAnimation = {
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 1s ease-in-out',
  };

  const kobrosPlatformImage = (
    <Box
      component="img"
      sx={{
        width: { xs: '100%', md: '53%' },
        height: 'auto',
        objectFit: 'contain',
        ...kobrosSectionAnimation,
        transitionDelay: '0.4s',
      }}
      alt="Kobros Platform Image"
      src={KobrosPlatformImage}
    />
  );
  const kobrosSectionButton = (
    <Button
      id="home-kobros-button"
      onClick={() => { navigate('/kobros'); }}
      variant="secondaryContained"
      sx={{
        height: '2.5rem',
        width: { xs: '100%', md: 'auto' },
        alignSelf: 'flex-start',
        ...kobrosSectionAnimation,
        transitionDelay: '0.4s',
        borderRadius: { xs: '1.5rem', md: 2 },
      }}
    >
      Conoce koBros
    </Button>
  );

  return (
    <Stack
      ref={ref}
      direction={isMobile ? 'column' : 'row'}
      sx={{ width: { xs: '90%', md: '76%' }, mb: { xs: 10, md: 20 } }}
      alignItems="center"
      spacing={isMobile ? 5 : 2}
    >
      <Stack
        direction="column"
        alignItems={isMobile ? 'center' : 'flex-start'}
        spacing={5}
        textAlign={isMobile ? 'center' : 'left'}
        sx={{ ...kobrosSectionAnimation }}
      >
        <KobrosLogo />
        <Typography variant="h2">Tu mejor asesor de cobranza</Typography>
        <KobrosDescription sx={{ ...kobrosSectionAnimation, transitionDelay: '0.2s' }} />
        {kobrosSectionButton}
      </Stack>
      {kobrosPlatformImage}
    </Stack>
  );
}

export default KobrosSection;
