import React from 'react';
import NewProductBanner from './NewProductBanner';
import { KOBROS_BANNER } from '../../../constants/kobros';
import { useIsMobile } from '../../../hooks';

function KobrosNewProductBanner() {
  const isMoblie = useIsMobile();
  const { title, color, textColor, icon, buttonId,
    buttonLink, buttonText, notShowUrls, availableCountries } = KOBROS_BANNER;

  return (
    !isMoblie
    && (
    <NewProductBanner
      title={title}
      textColor={textColor}
      color={color}
      icon={icon}
      buttonId={buttonId}
      buttonLink={buttonLink}
      buttonText={buttonText}
      notShowUrls={notShowUrls}
      availableCountries={availableCountries}
    />
    )
  );
}

export default KobrosNewProductBanner;
