import React from 'react';
import { AppBar, Toolbar, Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import logoText from '../../../assets/brand/images/logo_with_name.png';
import handleUtmRedirect from '../../../helpers/handleUtmRedirect';
import CollectionHeaderActions from '../../Kobros/CollectionHeaderActions';

const styles = {
  appBar: {
    backgroundColor: 'white',
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 2rem',
    backgroundColor: 'white',
  },
  logo: {
    width: 120,
  },
  logoContainer: {
    cursor: 'pointer',
    paddingLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  optionsContainer: {
    minWidth: '10rem',
  },
};

function DesktopNavbar({ buttons, pages }) {
  const {
    location: { pathname },
  } = window;
  const navigate = useNavigate();

  return (
    <AppBar elevation={1} sx={styles.appBar} position="sticky">
      <Toolbar sx={styles.toolbar}>
        <Grid container sx={styles.optionsContainer}>
          <Grid
            item
            xs={1}
            sx={styles.logoContainer}
            onClick={() => navigate('/')}
          >
            <a href="/" rel="noopener noreferrer">
              <img src={logoText} alt="logo" width="100%" />
            </a>
          </Grid>
          <Grid
            item
            alignItems="left"
            justifyContent="flex-start"
            xs={7}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {pages.map((page) => (
              <Button
                id={`navbar-${page.id}-desktop`}
                key={page.title}
                color={pathname === page.url ? 'primary' : 'text'}
                onClick={() => navigate(page.url)}
              >
                {page.title}
              </Button>
            ))}
          </Grid>
          <Grid
            item
            justifyContent="flex-end"
            sx={{ display: 'flex', alignItems: 'center' }}
            xs={4}
          >
            {pathname === '/kobros' && <CollectionHeaderActions show />}
            {pathname !== '/kobros'
              && buttons.map((button) => (
                <Grid item key={button.title}>
                  <Button
                    id={`navbar-${button.id}-desktop`}
                    variant={button.variant}
                    sx={{ marginLeft: '1rem' }}
                    onClick={() => handleUtmRedirect(button.url, button.queryParams)}
                  >
                    {button.title}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

DesktopNavbar.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  pages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default DesktopNavbar;
