import React from 'react';
import { Typography, Stack } from '@mui/material';

function KobrosText(props) {
  return (
    <Stack direction="row" alignItems="center">
      <Typography color="text" {...props}>
        <b>ko</b>
      </Typography>
      <Typography color="secondary" {...props}>
        <b>Bros</b>
      </Typography>
    </Stack>
  );
}

export default KobrosText;
