import React, { useMemo, useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { FEATURE_CARDS } from '../../constants/kobros';
import { useIsFirstTimeVisible } from '../../hooks';
import { FeatureCard } from './cards';

function Features() {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });

  const itemsAnimation = useMemo(() => ({
    opacity: isVisible ? 1 : 0,
    transform: `translateX(${isVisible ? 0 : -30}px)`,
    transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
  }), [isVisible]);

  return (
    <Box id="home-features" py={10} mx={{ xs: 5, md: 10 }}>
      <Typography ref={ref} variant="h2" mb={10} sx={{ ...itemsAnimation }}>
        Con todos los features necesarios...
      </Typography>
      <Stack
        direction="column"
        alignItems="center"
        spacing={{ xs: 12, md: 16 }}
        sx={{ ...itemsAnimation }}
      >
        {FEATURE_CARDS.map((feature, featureIndex) => (
          <FeatureCard
            key={`feature-${feature.id}`}
            feature={feature}
            featureIndex={featureIndex}
            isVisible={isVisible}
          />
        ))}
      </Stack>
      <Typography variant="h2" mt={10} textAlign="end" sx={{ ...itemsAnimation }}>
        ...para que cobrar no sea tema
      </Typography>
    </Box>
  );
}

export default Features;
