import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { useIsMobile } from '../../../hooks';

function PricingCard({ card, isVisible }) {
  const isMobile = useIsMobile();
  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const pricingCardImage = !isMobile && card.image && (
    <Box
      component="img"
      sx={{
        width: { xs: '100%', md: '60px' },
        height: 'auto',
        objectFit: 'contain',
        transform: `translatey(${isVisible ? 0 : 60}px)`,
        transition: 'transform 1.5s ease-in-out',
        transitionDelay: '1s',
        zIndex: 1,
      }}
      alt={`${card.title} image`}
      src={card.image}
    />
  );

  return (
    <Stack
      alignItems="center"
      justifyContent="end"
      sx={{
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 1s ease-in-out',
        transitionDelay: card.appearDelay,
      }}
    >
      {pricingCardImage}
      <Card
        sx={{
          height: { xs: 400, md: 450 },
          width: { xs: 280, lg: 350 },
          mx: 2,
          p: { xs: 2, xl: 4 },
          borderRadius: 4,
          zIndex: 1,
        }}
      >
        <Stack direction="column" height="100%" alignItems="center">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            my={2}
            spacing={1}
          >
            <Typography variant="h5"> {card.title}</Typography>
            <Typography variant="h5" color="gray.light">
              {' '}
              |{' '}
            </Typography>
            <Typography variant="h5" color="primary.main">
              {card.price}
            </Typography>
          </Stack>
          <Divider sx={{ width: 50, mb: 2, backgroundColor: 'black' }} />
          <Box height="100%" display="flex">
            {card.text}
          </Box>
          <Button
            id={`kobros-landing-pricing-button-${card.id}`}
            onClick={handleButtonClick}
            variant="contained"
            color="secondary"
            sx={{ maxWidth: 200, alignSelf: 'center', borderRadius: 4, mb: 1 }}
          >
            Solicitar demo
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
}

PricingCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired,
    appearDelay: PropTypes.string.isRequired,
    image: PropTypes.node,
  }).isRequired,
  isVisible: PropTypes.bool,
};

PricingCard.defaultProps = {
  isVisible: true,
};
export default PricingCard;
