import React, { useRef, useState } from 'react';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { MrKobrosSitting } from '../../assets/kobros';
import { useIsFirstTimeVisible, useIsMobile } from '../../hooks';
import CompositeTypography from '../Utils/typography/CompositeTypography';
import { BODY_PARTS, TITLE_PARTS } from '../../constants/kobros/kobros-header';
import CollectionDemoForm from './forms/CollectionDemoForm';
import CollectionHeaderActions from './CollectionHeaderActions';

function KobrosHeader() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const demoFormRef = useRef(null);

  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });
  const isIpad = useIsMobile('desktop');
  const isMobile = useIsMobile();

  return (
    <Grid
      container
      id="home-header"
      sx={{
        pt: { xs: 2, md: 3 },
        pb: { xs: 3, md: 0 },
        bgcolor: '#FFF',
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        ref={ref}
        display="flex"
        flexDirection="column"
        gap={{ xs: 2, md: 4 }}
        mt={{ md: 9 }}
        pl={{ xs: 0, md: '7%' }}
        pr={{ xs: 0, md: 11 }}
        sx={{
          opacity: isVisible ? 1 : 0,
          transform: `translateX(${isVisible ? 0 : -10}px)`,
          transition: 'opacity 1.5s ease-out, transform 1.5s ease-out',
        }}
      >
        <CompositeTypography
          textParts={TITLE_PARTS}
          width={{ xs: 'auto', md: '90%' }}
          px={{ xs: 7, md: 0 }}
          textAlign={{ xs: 'center', md: 'left' }}
        />
        <CompositeTypography
          textParts={BODY_PARTS}
          variant="body3"
          px={{ xs: 3, md: 0 }}
          maxWidth={{ md: 410 }}
          width={{ xs: 'auto', md: '90%' }}
          textAlign={{ xs: 'center', md: 'left' }}
        />
        <Typography
          variant="h3"
          color="secondary"
          fontWeight={500}
          display={{ xs: 'none', md: 'block' }}
        >
          ¡Pruébalo hoy!
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} mt={{ xs: 3, md: 4 }} px={{ xs: 3, md: 0 }}>
        <Stack
          component={Paper}
          ref={demoFormRef}
          direction="column"
          alignItems="center"
          spacing={3}
          maxWidth={600}
          elevation={4}
          py={4}
          mx={{ xs: 'auto', md: 0 }}
        >
          <Typography
            variant="body2"
            textAlign="center"
            fontWeight="bold"
            width={300}
          >
            Ingresa tus datos para solicitar una demo. ¡Te contactaremos a la
            brevedad!
          </Typography>
          <CollectionDemoForm />
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        mt={{ xs: 3, md: 4 }}
        display="flex"
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'flex-start' }}
      >
        <CollectionHeaderActions show={isMobile} />
      </Grid>
      <Grid
        item
        md={12}
        alignItems="end"
        display={{ xs: 'none', md: 'flex' }}
        justifyContent="center"
        maxWidth="80%"
        mx="auto"
        mb={-5}
        sx={{
          opacity: isVisible ? 1 : 0,
          transform: `translateY(${isVisible ? 0 : 10}px)`,
          transition: 'opacity 1.5s ease-in-out, transform 1.5s ease-in-out',
        }}
      >
        <Box
          component="img"
          justifyContent="flex-start"
          sx={{
            maxHeight: 200,
            height: 'auto',
            objectFit: 'contain',
            zIndex: 2,
          }}
          alt="MrKobro"
          src={MrKobrosSitting}
        />
        <Typography
          variant={isIpad ? 'h3' : 'h2'}
          py={{ xs: 0, md: 5 }}
          textAlign="center"
        >
          Cobra lo realmente importante, de la forma más eficiente y
          automatizada.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default KobrosHeader;
