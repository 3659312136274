import React from 'react';
import { MrKobrosSurprised } from '../../assets/kobros';
import { FingoPlanPricingText, PricingDotListText } from '../../components/Kobros/texts';

const ECONOMY_PLAN_BENEFITS = [
  'Hasta 2 usuarios',
  'Hasta 30 clientes monitoreados',
  'Envío de mails automatizados y recordatorios por mail ilimitados.',
  'Seguimiento del estado financiero de tus clientes',
];

const PRO_PLAN_BENEFITS = [
  'Hasta 5 usuarios',
  'Hasta 100 clientes monitoreados',
  'Envío de mails automatizados y recordatorios por mail limitados',
  'Seguimiento del estado financiero de tus clientes',
  '5 consultas al mes de potenciales clientes',
  'Recibe alertas y realiza gestiones por whatsapp',
];

const PRICING_CARDS = [
  {
    id: 'economy-plan',
    title: 'Plan Light',
    price: '1UF',
    text: <PricingDotListText items={ECONOMY_PLAN_BENEFITS} />,
    appearDelay: '0.1s',
  },
  {
    id: 'pro-plan',
    title: 'Plan Pro',
    price: '3UF',
    text: <PricingDotListText items={PRO_PLAN_BENEFITS} />,
    appearDelay: '0.3s',
  },
  {
    id: 'fingo-plan',
    title: 'Plan Fingo',
    price: 'GRATIS',
    text: <FingoPlanPricingText />,
    image: MrKobrosSurprised,
    appearDelay: '0.5s',
  },
];

export default PRICING_CARDS;
