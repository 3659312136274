import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { useIsMobile } from '../../../hooks';

function SolutionCard({ solution, solutionIndex, isVisible }) {
  const isMobile = useIsMobile();
  const solutionsStackDirection = useCallback(() => {
    if (isMobile) {
      return 'column';
    }
    return solutionIndex % 2 === 0 ? 'row' : 'row-reverse';
  }, [isMobile]);

  return (
    <Box
      width="100%"
      sx={{
        mt: { xs: 5, md: 12 },
        mb: { xs: 5, md: 0 },
        boxShadow: { xs: "'0 0 5px rgba(0, 0, 0, 0.3)'", md: 'none' },
        backgroundColor: { xs: 'fingoWhite', md: 'none' },
        pt: 2,
        borderRadius: 4,
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.7s ease-in-out',
        transitionDelay: `${solution.appearDelay}s`,
      }}
    >
      <Stack
        direction={solutionsStackDirection()}
        spacing={{ xs: 2, md: 5 }}
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Stack direction="column" maxWidth={280} spacing={2} width="100%">
          <Typography variant="h4">{solution.title}</Typography>
          <Typography variant="body3">{solution.description}</Typography>
        </Stack>
        <Box
          component="img"
          alt={`${solution.title}`}
          src={solution.image}
          sx={{ objectFit: 'contain', maxHeight: 230, maxWidth: { xs: '90vw', md: '100%' } }}
        />
      </Stack>
    </Box>
  );
}

SolutionCard.propTypes = {
  solution: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
    appearDelay: PropTypes.number.isRequired,
  }).isRequired,
  solutionIndex: PropTypes.number.isRequired,
  isVisible: PropTypes.bool,
};

SolutionCard.defaultProps = {
  isVisible: true,
};

export default SolutionCard;
