import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { animateScroll } from 'react-scroll';
import KobrosLogo from '../../../assets/brand/images/kobros_logo.png';
import KobrosNavbarWrapper from './KobrosNavbarWrapper';
import KOBROS_NAVBAR_HEIGHT from '../../../constants/kobros/layout';
import KobrosNavbarLink from './KobrosNavbarLink';

function DesktopNavbar({ pages }) {
  const navbarPage = (page) => (
    <KobrosNavbarLink toLink={page.url} key={`navbar-${page.id}-desktop`}>
      <Button id={`navbar-${page.id}-desktop`} variant="navlink">
        <Typography variant="subtitle5" color="text.primary">
          {page.title}
        </Typography>
      </Button>
    </KobrosNavbarLink>
  );

  const kobrosLogo = (
    <Box
      component="img"
      justifyContent="flex-start"
      sx={{
        maxHeight: KOBROS_NAVBAR_HEIGHT - 5,
        cursor: 'pointer',
      }}
      alt="Kobros"
      src={KobrosLogo}
      onClick={() => animateScroll.scrollToTop()}
    />
  );

  return (
    <KobrosNavbarWrapper>
      <Stack direction="row" alignItems="center" spacing={1}>
        {kobrosLogo}
        {pages.map((page) => navbarPage(page))}
      </Stack>
    </KobrosNavbarWrapper>
  );
}

DesktopNavbar.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default DesktopNavbar;
