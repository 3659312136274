import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import { openCollectionRegisterDialogVar } from '../../apollo-client/reactive-variables';

function CollectionHeaderActions({ show }) {
  return (
    <Stack direction="row" spacing={2} display={show ? 'inherit' : 'none'}>
      <Button
        id="whatsapp-kobros"
        variant="contained"
        color="secondary"
        startIcon={<WhatsApp fontSize="large" />}
        sx={{
          fontSize: { xs: 10, smartphone: 12, md: 14 },
          width: { xs: 110, smartphone: 160 },
          height: { xs: 30, md: 41 },
          borderRadius: 20,
        }}
        onClick={() => {
          window.open(process.env.REACT_APP_WHATSAPP_URI, '_blank');
        }}
      >
        Conversemos
      </Button>
      <Button
        id="register-dialog-kobros"
        variant="outlined"
        color="secondary"
        sx={{
          fontSize: { xs: 10, smartphone: 12, md: 14 },
          width: { xs: 110, smartphone: 160 },
          height: { xs: 30, md: 41 },
          borderRadius: 20,
        }}
        onClick={() => {
          openCollectionRegisterDialogVar(true);
        }}
      >
        Regístrate
      </Button>
    </Stack>
  );
}

CollectionHeaderActions.propTypes = {
  show: PropTypes.bool,
};

CollectionHeaderActions.defaultProps = {
  show: false,
};

export default CollectionHeaderActions;
