export const TITLE_PARTS = [
  { id: 1, text: 'Explora', color: 'text.main' },
  { id: 2, text: ' koBros: ', color: 'secondary.main' },
  { id: 3, text: 'la nueva', color: 'text.main' },
  { id: 4, text: ' herramienta de cobranza', color: 'secondary.main' },
  { id: 5, text: ' de Fingo', color: 'text.main' },
];
export const BODY_PARTS = [
  { id: 1, text: 'koBros', color: 'text.main', fontWeight: 'bold' },
  {
    id: 2,
    text: ' es una herramienta que pone a tu disposición un ',
    color: 'text.main',
  },
  {
    id: 3,
    text: 'asesor de cobranza con inteligencia artificial',
    color: 'text.main',
    fontWeight: 'bold',
  },
  { id: 4, text: ' que te ayuda a tomar ' },
  { id: 5, text: 'mejores decisiones con tus clientes', fontWeight: 'bold' },
  { id: 6, text: ', cobrar de forma ' },
  { id: 7, text: 'eficiente', fontWeight: 'bold' },
  { id: 8, text: ' y contar con los ' },
  { id: 9, text: 'flujos', fontWeight: 'bold' },
  { id: 10, text: ' cuando los necesitas.' },
];

export const EXISTING_ACCOUNT_DIALOG_BODY_PARTS = [
  { id: 1, text: 'koBros', color: 'secondary' },
  {
    id: 2,
    text: ' es la herramienta de cobranza de Fingo, por lo que podrás ingresar a koBros con tu cuenta de Fingo',
    color: 'text',
  },
];
