import { AutomaticAlerts, AutomaticEmails, ClientFinancialInformationSkeleton, CollectionHistory, Handshake, RolesAndProfiles, Target } from '../../assets/kobros';

const FEATURE_CARDS = [
  {
    id: 'supplier-portal',
    title: 'Portal de proveedores',
    description: 'Intégrate a portales de proveedores y reduce la incertidumbre en tu pago',
    image: Handshake,
  },
  {
    id: 'clients-financial-situation',
    title: 'Situación financiera de tus clientes',
    description: 'Mantente al tanto de la situación financiera de tus clientes',
    image: ClientFinancialInformationSkeleton,
  },
  {
    id: 'automatic-emails',
    title: 'Automatiza el envío de mails',
    description: 'Envía tus mails de forma automática',
    image: AutomaticEmails,
  },
  {
    id: 'roles-and-profiles',
    title: 'Roles y perfiles',
    description: 'Asigna colaboradores de tu equipo con distintos roles y perfiles',
    image: RolesAndProfiles,
  },
  {
    id: 'possible-clients',
    title: 'Entérate sobre potenciales clientes',
    description: 'Realiza consultas sobre potenciales clientes para saber sus condiciones de pago',
    image: Target,
  },
  {
    id: 'automatic-alerts',
    title: 'Alertas automáticas ',
    description: 'Alertas generadas con IA para anticipar problemas financieros de tus clientes',
    image: AutomaticAlerts,
  },
  {
    id: 'collection-history',
    title: 'Deja registro de la gestiones de cobranza realizadas',
    description: 'Realiza consultas sobre potenciales clientes para saber sus condiciones de pago',
    image: CollectionHistory,
  },
];

export default FEATURE_CARDS;
