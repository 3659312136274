import React from 'react';
import PropTypes from 'prop-types';
import { DotList } from '../../Utils/lists';

function PricingDotListText({ items }) {
  return (
    <DotList
      stackProps={{ spacing: 1 }}
      itemProps={{ spacing: 2 }}
      dotProps={{ sx: { fontSize: 8 } }}
      typographyProps={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
      items={items}
    />
  );
}

PricingDotListText.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingDotListText;
