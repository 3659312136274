import React from 'react';
import { useMediaQuery } from '@mui/material';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import getPlatformUrlByCountry from '../../../helpers/getPlatformUrlByCountry';

const platformUrl = getPlatformUrlByCountry();

const buttons = [
  {
    title: 'Inicia sesión',
    url: `${platformUrl}/login`,
    variant: 'outlined',
    id: 'login-mobile',
    queryParams: { loginType: 'signin' },
  },
  {
    title: 'Regístrate',
    url: `${platformUrl}/login`,
    variant: 'contained',
    id: 'register-mobile',
    queryParams: { loginType: 'signup' },
  },
];
const pages = [
  {
    title: 'Inicio',
    url: '/',
    id: 'start',
  },
  {
    title: 'Nuestros productos',
    url: '/products',
    id: 'products',
  },
  {
    title: 'Nuestra plataforma',
    url: '/platform',
    id: 'platform',
  },
  {
    title: 'Nosotros',
    url: '/team',
    id: 'team',
  },
  {
    title: 'Centro de ayuda',
    url: '/help',
    id: 'help',
  },
];

function Navbar() {
  const isMobile = useMediaQuery('(max-width: 920px)');
  if (isMobile) return <MobileNavbar buttons={buttons} pages={pages} />;
  return <DesktopNavbar buttons={buttons} pages={pages} />;
}

export default Navbar;
