import React, { useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { KobrosText } from '../Utils/text';
import { PRICING_CARDS } from '../../constants/kobros';
import { useIsFirstTimeVisible } from '../../hooks';
import { PricingCard } from './cards';

function Pricing() {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });

  return (
    <Box id="home-pricing" ref={ref} sx={{ bgcolor: 'background.light', py: 8 }}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        sx={{ opacity: isVisible ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out' }}
        mb={{ xs: 4, md: 1 }}
      >
        <Typography variant="h2">Tarifas</Typography>
        <KobrosText variant="h2" />
      </Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        width="100%"
        justifyContent="center"
        spacing={{ xs: 5, md: 0 }}
      >
        {PRICING_CARDS.map((card) => (
          <PricingCard key={`${card.id}-pricing-card`} card={card} isVisible={isVisible} />
        ))}
      </Stack>
    </Box>
  );
}

export default Pricing;
