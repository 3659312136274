import { MexAvisoPrivacidad, MexAvisoUne, TyCFingoMexico } from '../../assets/legal';

const legalLinks = [
  {
    text: 'Términos y condiciones',
    href: TyCFingoMexico,
    isDownload: true,
    redirect: null,
  },
  {
    text: 'Costos y comisiones de operar con Fingo',
    href: null,
    isDownload: false,
    redirect: '/help/costs-and-comissions',
  },
  {
    text: 'Aviso de privacidad',
    href: MexAvisoPrivacidad,
    isDownload: true,
    redirect: null,
  },
  {
    text: 'Aviso UNE',
    href: MexAvisoUne,
    isDownload: true,
    redirect: null,
  },
];

export default legalLinks;
