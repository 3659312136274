import React, { useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { SOLUTIONS_CARDS } from '../../constants/kobros';
import { useIsFirstTimeVisible } from '../../hooks';
import SolutionCard from './cards/SolutionCard';

function Solutions() {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useIsFirstTimeVisible({ ref, isVisible, setIsVisible });

  return (
    <Box id="home-solutions" sx={{ bgcolor: 'background.light' }}>
      <Stack
        ref={ref}
        height="100%"
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        mx={2}
        mt={{ xs: 10, md: 5 }}
      >
        {SOLUTIONS_CARDS.map((solution, solutionIndex) => (
          <SolutionCard
            key={`solution-${solution.id}`}
            solution={solution}
            solutionIndex={solutionIndex}
            isVisible={isVisible}
          />
        ))}
      </Stack>
    </Box>
  );
}

export default Solutions;
