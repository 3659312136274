import { Stack } from '@mui/material';
import React from 'react';
import KobrosHeader from './KobrosHeader';
import Solutions from './Solutions';
import Features from './Features';
import Pricing from './Pricing';
import FAQ from './FAQ';
import KobrosNavbar from './navbar';

function Kobros() {
  return (
    <Stack direction="column" maxWidth={2048} mx="auto">
      <KobrosNavbar />
      <KobrosHeader />
      <Solutions />
      <Features />
      <Pricing />
      <FAQ />
    </Stack>
  );
}

export default Kobros;
