import { useMutation } from '@apollo/client';
import { USER_INTERACTION_REGISTER } from '../graphql/mutations';

const useReactTrackingLayer = (frontendName) => {
  const [registerAction] = useMutation(USER_INTERACTION_REGISTER, {
    onError: () => { },
  });
  const dispatcher = (trackingEvent) => {
    const {
      button,
      action,
      route,
      searchParams,
      session,
      isMobile,
    } = trackingEvent;
    registerAction({
      variables: {
        action: {
          frontendName,
          route,
          searchParams,
          isMobile,
          action,
          session,
          button,
        },
      },
    });
  };
  return dispatcher;
};

export default useReactTrackingLayer;
