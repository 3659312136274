import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import KOBROS_NAVBAR_HEIGHT from '../../../constants/kobros/layout';
import DESKTOP_NAVBAR_HEIGHT from '../../../constants/desktop-navbar';

function KobrosNavbarLink({ toLink, children }) {
  return (
    <Link
      to={toLink}
      offset={-(KOBROS_NAVBAR_HEIGHT + DESKTOP_NAVBAR_HEIGHT)}
      smooth
      duration={1000}
    >
      {children}
    </Link>
  );
}

KobrosNavbarLink.propTypes = {
  toLink: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default KobrosNavbarLink;
