import KobrosLogoWhiteImage from '../../assets/brand/images/kobros_logo_white.png';

const KOBROS_BANNER = {
  title: 'El nuevo software de cobranza de Fingo',
  color: 'secondary.main',
  textColor: 'white',
  icon: KobrosLogoWhiteImage,
  buttonId: 'kobros-banner',
  buttonLink: '/kobros',
  buttonText: 'Explorar koBros',
  notShowUrls: ['/kobros', '/platform', '/team', '/help'],
  availableCountries: ['Chile'],
};

export default KOBROS_BANNER;
