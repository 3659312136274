import { useEffect } from 'react';
import PropTypes from 'prop-types';

function useIsFirstTimeVisible({ ref, isVisible, setIsVisible }) {
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (isVisible) return;
      setIsVisible(entry.isIntersecting);
    });
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [isVisible]);
}

useIsFirstTimeVisible.propTypes = {
  ref: PropTypes.shape({
    current: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

export default useIsFirstTimeVisible;
