import { Grid, Typography, Card, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import HelpFAQ from './HelpFAQ';
import AvatarIcon from '../../assets/helpCenter/images/avatar.png';
import PaperIcon from '../../assets/helpCenter/images/paper.png';
import PenIcon from '../../assets/helpCenter/images/pen.png';
import { createAccountVideo, factoringVideo, orderingVideo } from '../../constants/help-center-links';
import { useIsMobile } from '../../hooks';

const styles = {
  container: {
    marginBlock: '2rem',
    '@media (max-width:600px)': {
      minHeight: '10rem',
      marginBlock: '1rem',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: '1rem',
    justifyContent: 'space-evenly',
    '@media (max-width:600px)': {
      width: '90%',
    },
  },
  acordionContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '65%',
    marginBottom: '3rem',
    '@media(max-width:600px)': {
      width: '90%',
      marginBottom: '0rem',
    },
  },
  card: {
    height: '11rem',
    width: '14rem',
    borderRadius: '0.8rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-evenly',
    '&:hover': {
      backgroundColor: 'background.main',
    },
    '@media (max-width:600px)': {
      height: '8rem',
      width: '7.3rem',
    },
  },
  icon: {
    width: '4rem',
  },
  iconMobile: {
    width: '2.5rem',
  },
};

const cardsContent = [
  {
    icon: AvatarIcon,
    subtitle: '¿Cómo crear una cuenta?',
    url: createAccountVideo,
  },
  {
    icon: PenIcon,
    subtitle: '¿Cómo operar Ordering?',
    url: orderingVideo,
  },
  {
    icon: PaperIcon,
    subtitle: '¿Cómo operar Factoring?',
    url: factoringVideo,
  },
];

function HelpCenterBody() {
  const [helpUrl, setHelpUrl] = useState(null);
  const isMobile = useIsMobile('sm');
  const onExpand = (url) => {
    if (url === helpUrl) {
      setHelpUrl(null);
    } else {
      setHelpUrl(url);
    }
  };
  return (
    <>
      <Grid container direction="column" justifyContent="space-evenly" alignItems="center" sx={styles.container}>
        <Grid item sx={styles.inputContainer}>
          <Typography variant={isMobile ? 'h2' : 'h1'} textAlign="center" sx={{ mb: '1.5rem' }}>
            ¿En qué te podemos ayudar?
          </Typography>
          <Button
            id="helpcenter-go-to-help-center"
            variant="contained"
            onClick={() => window.open('https://ayuda.fingo.cl/es/')}
            sx={{ width: '100%', margin: 'auto', fontWeight: '600' }}
          >
            Ir al centro de ayuda
          </Button>
        </Grid>
        <Grid container direction="row" justifyContent="center" spacing={isMobile ? 1 : 4} marginBlock="2rem">
          {cardsContent.map((content) => (
            <Grid key={content.subtitle} item>
              <Card sx={styles.card} elevation={5} onClick={() => onExpand(content.url)}>
                <img src={content.icon} alt="help-icon" style={isMobile ? styles.iconMobile : styles.icon} />
                <Typography textAlign="center">
                  {content.subtitle}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid sx={styles.acordionContainer}>
          <Accordion sx={styles.acordion} elevation={0} expanded={helpUrl}>
            <AccordionSummary
              sx={styles.acordionSumary}
            />
            <AccordionDetails>
              <ReactPlayer
                url={helpUrl}
                width={isMobile ? '90vw' : '60vw'}
                height={isMobile ? '30vh' : '60vh'}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <HelpFAQ search="" />
    </>
  );
}

export default HelpCenterBody;
