import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from '@mui/material';
import KOBROS_NAVBAR_HEIGHT from '../../../constants/kobros/layout';
import DESKTOP_NAVBAR_HEIGHT from '../../../constants/desktop-navbar';

function KobrosNavbarWrapper({ children }) {
  return (
    <AppBar
      elevation={0}
      position="sticky"
      sx={{
        height: KOBROS_NAVBAR_HEIGHT,
        justifyContent: 'center',
        transition: 'background-color 0.3s',
        backgroundColor: 'background.light',
        zIndex: (theme) => theme.zIndex.drawer,
        top: DESKTOP_NAVBAR_HEIGHT,
        alignItems: 'center',
        display: { xs: 'none', md: 'flex' },
      }}
    >
      <Toolbar>
        {children}
      </Toolbar>
    </AppBar>
  );
}

KobrosNavbarWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default KobrosNavbarWrapper;
