import { Grid } from '@mui/material';
import React from 'react';
import ProductBody from './ProductBody';
import OurProductsHeader from '../OurProductsHeader';
import WorkerPhoto from './WorkerPhoto';
import ProductSpecials from './ProductSpecials';
import KobrosNewProductBanner from '../../Utils/banner/KobrosNewProductBanner';

const styles = {
  container: {
    overflow: 'hidden',
  },
};

function Products() {
  return (
    <Grid
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
      container
      sx={styles.container}
      spacing={0}
    >
      <KobrosNewProductBanner />
      <OurProductsHeader />
      <ProductBody />
      <ProductSpecials />
      <WorkerPhoto />
    </Grid>
  );
}

export default Products;
