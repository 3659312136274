import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useGetCountry } from '../../../helpers';

function NewProductBanner({ title, color, textColor, icon, buttonId,
  buttonLink, buttonText, notShowUrls, availableCountries }) {
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(true);

  const { location: { pathname } } = window;

  const validUrl = !notShowUrls.includes(pathname);
  const validCountry = availableCountries.includes(useGetCountry());

  const spacer = (
    <Box flex={1} />
  );

  return (
    (showBanner && validUrl && validCountry)
    && (
    <Box position="relative">
      <Stack
        width="100%"
        position="fixed"
        left={0}
        right={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        height={55}
        sx={{
          backgroundColor: color,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        {spacer}
        <Box
          component="img"
          justifyItems="flex-end"
          sx={{ maxHeight: 45,
            maxWidth: 'auto',
            cursor: 'pointer',
            objectFit: 'contain',
          }}
          alt="New Product Banner Icon"
          src={icon}
          onClick={() => { navigate(buttonLink); }}
        />
        <Typography variant="subtitle5" fontWeight={700} pl={4} color={textColor}>{title}</Typography>
        <Button
          id={buttonId}
          variant="outlined"
          sx={{
            backgroundColor: color,
            borderColor: textColor,
            color: textColor,
            '&:hover': {
              color,
              borderColor: textColor,
              backgroundColor: textColor,
            },
            ml: 4,
          }}
          onClick={() => navigate(buttonLink)}
        >
          {buttonText}
        </Button>
        {spacer}
        <Button onClick={() => setShowBanner(false)}>
          <CloseIcon sx={{ color: textColor }} />
        </Button>
      </Stack>
    </Box>
    )
  );
}

NewProductBanner.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  buttonId: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  notShowUrls: PropTypes.arrayOf(PropTypes.string),
  availableCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
};

NewProductBanner.defaultProps = {
  notShowUrls: [],
};

export default NewProductBanner;
