import React from 'react';
import { Stack, Typography } from '@mui/material';
import { FAQ_ITEMS } from '../../constants/kobros';
import KobrosAccordionItem from '../Utils/accordions/KobrosAccordionItem';

function FAQ() {
  return (
    <Stack
      id="home-faq"
      direction="column"
      alignItems="center"
      minHeight="80vh"
      spacing={5}
      p={{ xs: 2, md: 5 }}
    >
      <Typography flex={1} variant="h2" textAlign="center" pt={{ xs: 3, md: 0 }}>
        Preguntas Frecuentes
      </Typography>
      <Stack width={{ xs: '100%', md: '75vw' }} maxWidth="100%" flex={2}>
        {FAQ_ITEMS.map((question) => (KobrosAccordionItem(question)))}
      </Stack>
    </Stack>
  );
}

export default FAQ;
