import { Stack, Typography } from '@mui/material';
import React from 'react';

function FingoPlanPricingText() {
  return (
    <Stack direction="column" textAlign="center" spacing={1} mt="60px" mx={2}>
      <Typography variant="h5">¡Leíste bien!</Typography>
      <Typography variant="body3">
        Cada mes que operes con Fingo, tienes el Plan Light de koBros gratis para ti!
      </Typography>
    </Stack>
  );
}
export default FingoPlanPricingText;
