import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useIsMobile } from '../../../hooks';

function FeatureCard({ feature, featureIndex }) {
  const isMobile = useIsMobile();
  const isDesktopAndOddIndex = (index) => index % 2 !== 0 && !isMobile;

  const itemsStackDirection = () => {
    if (isMobile) {
      return 'column';
    }
    return isDesktopAndOddIndex(featureIndex) ? 'row' : 'row-reverse';
  };

  return (
    <Stack
      direction={itemsStackDirection()}
      spacing={isMobile ? 3 : 5}
      alignItems="center"
    >
      <Stack
        direction="column"
        maxWidth={370}
        spacing={2}
        alignItems={isDesktopAndOddIndex(featureIndex) ? 'end' : 'start'}
        textAlign={isDesktopAndOddIndex(featureIndex) ? 'end' : 'start'}
      >
        <Stack direction={isDesktopAndOddIndex(featureIndex) ? 'row' : 'row-reverse'}>
          <Typography variant="h4" minWidth={290}>{feature.title}</Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: 'primary.main',
              width: '3px',
              mr: isDesktopAndOddIndex(featureIndex) ? 0 : 2,
              ml: isDesktopAndOddIndex(featureIndex) ? 2 : 0,
            }}
          />
        </Stack>
        <Typography variant="body3">{feature.description}</Typography>
      </Stack>
      <Box
        component="img"
        alt={`${feature.title}`}
        src={feature.image}
        sx={{ objectFit: 'contain', maxHeight: '100%', maxWidth: 140 }}
      />
    </Stack>
  );
}

FeatureCard.propTypes = {
  feature: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
  }).isRequired,
  featureIndex: PropTypes.number.isRequired,
};

export default FeatureCard;
