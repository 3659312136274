import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import ButtonTrackerApp from './ButtonTrackerApp';
import { useIsMobile, useReactTrackingLayer, useUniqueHash } from '../../hooks';

function TrackerProvider({ children }) {
  const dispatch = useReactTrackingLayer('landing-page');
  const location = useLocation();
  const isMobile = useIsMobile();
  const session = useUniqueHash();
  const sessionCookie = new Cookies();
  sessionCookie.set('session', session);
  // Tracking code for changing pages
  const { Track, trackEvent } = useTracking(
    {
      route: location.pathname,
      searchParams: location.search,
      isMobile,
      session,
    },
    { dispatch },
  );
    // send load and unload events on page change
  useEffect(() => {
    trackEvent({ action: 'LOADED' });
  }, [location.pathname, trackEvent, isMobile, location.search]);
  return (
    <Track>
      <ButtonTrackerApp>
        {children}
      </ButtonTrackerApp>
    </Track>
  );
}

TrackerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TrackerProvider;
