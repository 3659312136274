import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function CalendarBook({ path }) {
  const embedRef = useRef(null);
  const completePath = `kobros/15-minute-meeting${path}`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://asset-tidycal.b-cdn.net/js/embed.js';
    script.async = true;
    script.onload = () => {
      if (window.TidyCal && window.TidyCal.init
         && embedRef.current && embedRef.current.parentNode) {
        window.TidyCal.init(embedRef.current);
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="tidycal-embed" data-path={completePath} ref={embedRef} />;
}

export default CalendarBook;

CalendarBook.propTypes = {
  path: PropTypes.string.isRequired,
};
