import { ClientInformation, ManagePriorities, WhatsappChat } from '../../assets/kobros';

const SOLUTIONS_CARDS = [
  {
    id: 'client-information',
    title: 'Información del cliente',
    description: 'En base a algoritmos que consolidan la información de Fingo, KoBros te entrega información muy valiosa sobre tus clientes como su comportamiento de pago, alertas de juicios y otros problemas que puedan afectar tu pago.',
    image: ClientInformation,
    appearDelay: 0.1,
  },
  {
    id: 'manage-according-to-priorities',
    title: 'Gestiona según prioridades',
    description: 'Utilizando inteligencia artificial KoBros te ayuda a establecer prioridades y cobrar de manera eficiente utilizando de mejor manera tu tiempo.',
    image: ManagePriorities,
    appearDelay: 0.5,
  },
  {
    id: 'whatsapp-notifications',
    title: 'Recibe notificaciones y haz gestiones de cobranza con whatsapp directamente',
    description: '*Incluído en el Plan Pro',
    image: WhatsappChat,
    appearDelay: 1,
  },
];

export default SOLUTIONS_CARDS;
